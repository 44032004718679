

import { EmptyPoolExclusionConstant } from "presentation/constant/EmptyPoolExclusion/EmptyPoolExclusionConstant";
import { useEmptyPoolExclusionCriteriaVM } from "presentation/hook/EmptyPoolExclusionCriteria/useEmptyPoolExclusionCriteriaVM";
import { useEmptyExclCriteriaTracked } from "presentation/store/EmptyPoolExclusionCriteria/EmptyPoolExclusionCriteriaProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import { Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import { EmptyPoolExclusionCriteriaComponentPanel } from "./Form/EmptyPoolExclusionCriteriaComponentPanel";
import EmptyPoolExclusionCriteriaComponentTablePanel from "./Table/EmptyPoolExclusionCriteriaComponentTablePanel";
export const EmptyPoolExclusionCriteriaMaintenance: React.FC = () => {
    const [criteriaState] = useEmptyExclCriteriaTracked();
    const {isSliderOpen } = criteriaState.masterState;
    
    const [isLoading, setIsLoading] = useState(false);
    const criteriaVM = useEmptyPoolExclusionCriteriaVM();
    
    useEffect(() => {
        const initialScreen = async() => {   
            setIsLoading(true);
            try {     
                const results = await Promise.allSettled([
                    criteriaVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    } 
                })
            } catch(error) {
                setIsLoading(false);
            }
        }
        initialScreen().then(async () => {
            criteriaVM.searchEmptyPoolExclusion().then((data) => {            
                setIsLoading(false)
            }).catch(error => {            
                setIsLoading(false)
            });
        }).catch(error => {            
            setIsLoading(false)
        });
        
    }, [criteriaVM])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <div className='main-comp-wrapper'>
                <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
                    <HeaderTitle>
                        {EmptyPoolExclusionConstant.Title.EMPTY_POOL_EXCLUSION_MAIN}
                    </HeaderTitle>
                </Sidebarheader>
                <SliderPanel
                    isOpen={isSliderOpen}
                    leftSectionWidth={isSliderOpen?'73%':'100%'}
                    rightSectionWidth={isSliderOpen?'28%':'0%'}
                    draggable={true}
                    leftChildren={<EmptyPoolExclusionCriteriaComponentTablePanel/>}
                    rightChildren={<EmptyPoolExclusionCriteriaComponentPanel/>} />
            </div>
}