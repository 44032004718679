import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { EmptyPoolExclusionConstant } from "./EmptyPoolExclusionConstant";

const EMPTY_POOL_EXCL_MAIN_CONSTANT = EmptyPoolExclusionConstant.Table;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_EMPTY_POOL_EXCL_COMP_COL_DEF: any[] = [
        {
            headerName: EMPTY_POOL_EXCL_MAIN_CONSTANT.OPS_LINE,
            field: "opsLine",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            
        },
        {
            headerName: EMPTY_POOL_EXCL_MAIN_CONSTANT.CHARGE_ON_COMPANY,
            field: "chargeOnCompany",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
            headerName: EMPTY_POOL_EXCL_MAIN_CONSTANT.EFFECTIVE_DATE,
            field: "effectiveDate",
            enableRowGroup: true,
            enablePivot: true,
            enableValue: true,
            filter: false,
            width: 150,
            dataType: "date"
        },
        {
            headerName: EMPTY_POOL_EXCL_MAIN_CONSTANT.OPERATING_TML,
            field: "operatingTml",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 160,
        },
        {
            headerName: EMPTY_POOL_EXCL_MAIN_CONSTANT.EXPIRY_DATE,
            field: "expiryDate",
            enableRowGroup: true,
            enablePivot: true,
            enableValue: true,
            filter: false,
            width: 150,
            dataType: "date"
        },
        {
            headerName: EMPTY_POOL_EXCL_MAIN_CONSTANT.CONTAINER_TYPE,
            field: "cntrType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 170,
        },
        {
            headerName: EMPTY_POOL_EXCL_MAIN_CONSTANT.CONTAINER_STATUS,
            field: "cntrStatus",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 170,
        },
        {
            headerName: EMPTY_POOL_EXCL_MAIN_CONSTANT.BLOCK,
            field: "block",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 100,
        },
        {
            headerName: EMPTY_POOL_EXCL_MAIN_CONSTANT.STACK,
            field: "stack",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 100,
        },
        {
            headerName: EMPTY_POOL_EXCL_MAIN_CONSTANT.LANE,
            field: "lane",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 100,
        },
        {
            headerName: EMPTY_POOL_EXCL_MAIN_CONSTANT.TIER,
            field: "tier",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 100,
        },
        {
            headerName: EMPTY_POOL_EXCL_MAIN_CONSTANT.LOCATION_TYPE,
            field: "locationType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 170,
        },
        {
            headerName: EMPTY_POOL_EXCL_MAIN_CONSTANT.ACTIVE_IND,
            field: "activeInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 130,
        },
        
        

].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: { [key: string]: string }, fieldName: string) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}
